.App {
    text-align: center;
    background-image: url(./bg_parchemin.jpg);
    background-position: 50% 50%;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    box-sizing: border-box;
}

.App > div {
    flex: 1 1;
}

.App-link {
    color: #f5ecec;
    font-size: calc(10px + 2vmin);
    background: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    padding: 20px;
    text-decoration: none;
}

button {
    color: #f5ecec;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    padding: 5px;
    text-decoration: none;
    border-style: solid;
}

.App-inventory-btn {
    position: fixed;
    top: 10px;
    left: 5px;
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    background-image: url(./pages/home/logo.svg);
    background-size: contain;
    cursor: pointer;
    z-index: 6010;
}

.written-page {
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100vh;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    text-align: left;
}

.written-page p {
    font-family: 'Open Sans', sans-serif;
    padding: 10px;
    align-self: start;
}

.text-center {
    text-align: center !important;
}


.Carnet-Page h2 {
    text-align: center;
}
.Carnet-Page {
    background-repeat: repeat;
    background-image: url(./bg_notebook.jpg);
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    text-align: left;
    font-size: 23px;
}

.Carnet-Page .page-indicator {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-size: 10px;
    position: absolute;
    right: 15px;
    bottom: 5px;
}

.Carnet-Page .cistercien {
    height: 25px;
}