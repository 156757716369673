.Page-Pirahas blockquote {
    margin: 5px;
    background-color: rgba(255, 200, 200, 0.6);
    padding: 15px;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Pirata One', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Page-Pirahas .dallesGrid {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 1px;
    width: 100%;
    background-color: lightgray;
}

.Page-Pirahas .dallesGrid .itemGrid {
    aspect-ratio: 1;
    position: relative;

}


.Page-Pirahas .dallesGrid .itemGrid.selected:before {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    top: 25%;
    left: 25%;
    background-color: white;
    border: 1px solid black;
    font-size: 8px;
    color: white;
}

.Page-Pirahas button {
    flex: 0 1;
    font-size: 24px;
    text-transform: uppercase;
    padding: 0 10px;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
    margin-bottom: 15px;
}

.Page-Pirahas .error {
    color: darkred;
}