
.App-logo {
  height: 60vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: calc(50px + 2vmin);
  color: rgba(40, 0, 0, 0.7);
  text-shadow: rgba(100, 50, 0, 0.5) 0 0 10px;
}

.App-header p {
  margin: 0;
}

.App-header .App-Link {
  margin: 50px 0;
  font-size: calc(30px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
