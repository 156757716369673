.room-picture.no-balance #balance {
    display: none;
}

.room-picture.no-carnet #carnet {
    display: none;
}

.room-picture.no-coffre #coffre {
    display: none;
}

.room-picture.no-epee #epee {
    display: none;
}

.Page-Piece h3 {
    text-align: center;
    padding: 15px;
}