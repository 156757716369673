.Coffre-Page {

}

.Coffre-Page form {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Coffre-Page input {
    font-size: 50px;
    flex: 1 1;
    width: 50vw;
}

.Coffre-Page button {
    align-self: stretch;
    margin-left: 5px;
}

.Coffre-Page .error {
    color: darkred;
}

.Coffre-Page .secondary-form {
    flex-direction: column;
    margin: 10px;
}
.Coffre-Page .secondary-form img {
    margin-right: 10px;
}