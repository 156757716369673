.Balance-Page p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.Balance-Page form {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: center;
}

.Balance-Page form > button {
    margin-left: 5px;
}

.Balance-Page form .Poids-List {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.Balance-Page form .Poids-List > div {
    display: flex;
    width: 100%;
}

.Balance-Page form .Poids-List > div > span {
    flex: 1 0 50px;
}

.Balance-Page form .Poids-List > div > input {
    flex: 1 1 100px;
    width: 100px;
}

.Balance-Page .error {
    color: red;
    margin-top: 0;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}