.Main-Nav-dropout {
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 6000;
}

.Main-Nav h4 {
    margin: 0.5em
}
.Main-Nav ul {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    margin-left: 1em;
    padding-left: 1em;
    list-style: circle;
}