.Page-Marocanar form {
    display: flex;
    flex-direction: column;
}
.Page-Marocanar form label {
    padding: 5px;
}
.Page-Marocanar .error {
    color: darkred;
}
.Page-Marocanar form label {
    display: flex;
    flex-direction: row;

}
.Page-Marocanar form label > span {
    flex: 1 1;
}
.Page-Marocanar form label > input {
    flex: 2 1;
}