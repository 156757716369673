.Page-Macapa hr {
    color: lightgray;
    border-style: inset;
    border-width: 1px;
    margin: 0.5em auto;
    width: 80%;
}
.Page-Macapa blockquote {
    background-color: white;
    font-size: 23px;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
}