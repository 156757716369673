.Crosswords h4 {
    margin: 0.5em
}
.Crosswords ul {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    margin-left: 1em;
    padding-left: 1em;
    list-style: none;
    font-size: 16px;
}

.Crosswords .Crosswords-grid {
    width: calc(100vw - 30px);
}