.Requins-Page p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.Requins-Page form {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 15px;
}
.Requins-Page ul {
    margin: 0;
    align-self: start;
}

.Requins-Page form > button {
    margin-left: 5px;
}


.Requins-Page .error {
    color: red;
    margin-top: 0;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}