.Page-Interro h4 {
    margin: 0;
    font-size: 23px;
    font-style: italic;
    text-align: right;
}

.Page-Interro blockquote {
    background-color: white;
    font-size: 23px;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
}

.Page-Interro p {
    margin-top: 5px;
    margin-bottom: 5px;
}