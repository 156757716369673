.Page-Malaury p {
    margin-top: 3px;
    margin-bottom: 3px;
}
.Page-Malaury form {
    display: flex;
    align-self: stretch;
    padding: 0 15px 15px 15px;
    flex-direction: column;
}
.Page-Malaury form label {
    padding: 5px;
}
.Page-Malaury .error {
    color: darkred;
    margin: 0;
}
.Page-Malaury ul {
    margin: 0;
    padding: 0;
}
.Page-Malaury ul > li{
    margin-left: 20px;
}

.Page-Malaury .soluce {
    margin: 15px;
    align-self: stretch;
    font-family: 'Open Sans', sans-serif;
    padding: 10px;
}