.Epee-Page p {
    margin-top: 0;
    margin-bottom: 5px;
}
.Epee-Page .score-indicator {
    margin: 0;
    padding: 0 20px ;
    font-weight: bolder;
}
.Epee-Page .start-button-wrapper {
    text-align: center;
    margin-top: 15px;

}

.Epee-Page .start-button-wrapper button {
    font-weight: bolder;
    padding: 10px;
}

.Epee-Page .reset-button-wrapper {
    position: absolute;
    bottom: 5px;
    right: 5px;
}


.Epee-Page .play-area {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    position: relative;
}

.Epee-Page .count-down {
    font-size: 50vw;
}

.Epee-Page .text-to-learn {
    font-size: 42px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bolder;
}

.Epee-Page .text-to-learn .number {
    font-size: 22px;
}

.Epee-Page .joueur {
    position: absolute;
    font-family: 'Open Sans', sans-serif;
    font-size: 23px;
}

.Epee-Page .joueur1 {
    transform: rotate(90deg);
    transform-origin: bottom left;
    left: 5px;
    top: 30px;
}

.Epee-Page .joueur2 {
    transform: rotate(-90deg);
    transform-origin: bottom right;
    right: 5px;
    top: 30px;
}

.Epee-Page .play-area .guess-phase {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.Epee-Page .play-area .already-solved {
    margin: 0 0 15px 0;
    padding: 0;
    align-self: center;

}
.Epee-Page .play-area .already-solved li {
    list-style: none;
}

.Epee-Page .play-area .current-proposal {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 10px;
}

.Epee-Page .play-area .current-proposal-number {
    text-align: center;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    border-bottom: 1px solid black;
}

.Epee-Page .play-area .choices {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 15px;
}

.Epee-Page .play-area .choices > div {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding-top: 15px;
}

.Epee-Page .play-area .choices .separator {
    flex: 0 0 1px;
    border-left: 1px solid black;
}

.Epee-Page .play-area .choices label {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
}
.Epee-Page .play-area .word-choices label {
    justify-content: right;
}

.Epee-Page .play-area .choices .proposed-color {
    width: 35px;
    height: 35px;
    border: 1px solid black;
    margin-left: 5px;
}

.Epee-Page .already-solved {

    font-family: 'Open Sans', sans-serif;
}