.form-chap1-bagages {
    display: flex;
    width: calc(100vw - 20px);

}

.form-chap1-bagages input {
    flex: 1 1;
    font-size: 24px;
    padding: 0 10px;
    min-width: 100px;
    border-radius: 5px 0 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.form-chap1-bagages button {
    flex: 0 1;
    font-size: 24px;
    padding: 0 10px;
    border-radius: 0 5px 5px 0;
    background-color: rgba(255,255,255, 0.8);
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
    border-width: 1px 1px 1px 0;
}